/* eslint-disable indent,prettier/prettier */

export enum SwiperNavArrowOrientation {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum SwiperNavArrowColor {
  PINK = 'pink',
  WHITE = 'white',
}

export interface ISwiperNavArrowProps {
  orientation?: SwiperNavArrowOrientation;
}
export interface ISwiperNavArrowProps {
  orientation?: SwiperNavArrowOrientation;
  color?: SwiperNavArrowColor;
  className?: string;
}

const getArrowByColor = (color: SwiperNavArrowColor, whiteArrow: JSX.Element, pinkArrow: JSX.Element) =>
  color === SwiperNavArrowColor.WHITE ? whiteArrow : pinkArrow;

export const SwiperNavArrow = ({
  orientation = SwiperNavArrowOrientation.LEFT,
  color = SwiperNavArrowColor.WHITE,
  className,
}: ISwiperNavArrowProps) => {
  const leftSwiperNavArrowWhite = (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M10 0.614181L4.00012 0.614014L0 7.11401L6.00073 7.11372L10 0.614181Z"
        fill="#F9FAFB"
      />
      <path
        d="M10 13.6137L3.99968 13.614L0 7.11431L6.00032 7.11401L10 13.6137Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );

  const rightSwiperNavArrowWhite = (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M0 0.614181L5.99988 0.614014L10 7.11401L3.99927 7.11372L0 0.614181Z"
        fill="#F9FAFB"
      />
      <path
        d="M0 13.6137L6.00032 13.614L10 7.11431L3.99968 7.11401L0 13.6137Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );

  const leftSwiperNavArrowPink = (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M10 0.728195L4.00012 0.728027L0 7.22803L6.00073 7.22773L10 0.728195Z"
        fill="#C93D94"
      />
      <path
        d="M10 13.7277L3.99968 13.728L0 7.22832L6.00032 7.22803L10 13.7277Z"
        fill="#862963"
      />
    </svg>
  );

  const rightSwiperNavArrowPink = (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M0 0.614181L5.99988 0.614014L10 7.11401L3.99927 7.11372L0 0.614181Z"
        fill="#C93D94"
      />
      <path
        d="M0 13.6137L6.00032 13.614L10 7.11431L3.99968 7.11401L0 13.6137Z"
        fill="#862963"
      />
    </svg>
  );

  switch (orientation) {
    case SwiperNavArrowOrientation.LEFT:
      return getArrowByColor(color, leftSwiperNavArrowWhite, leftSwiperNavArrowPink);
    case SwiperNavArrowOrientation.RIGHT:
      return getArrowByColor(color, rightSwiperNavArrowWhite, rightSwiperNavArrowPink);
    default:
      return getArrowByColor(color, rightSwiperNavArrowWhite, rightSwiperNavArrowPink);
  }
};
