'use client';

import { IListContent } from '@/interfaces/layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/css/navigation';
import './index.scss';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BREAKPOINTS } from '@/constants/breakpoints';
import { SwiperNavButton } from '../SwiperNavButton';
import { SwiperNavArrowOrientation } from '@/images/svg/SwiperNavArrow';

interface IProps {
  list: IListContent[];
}

export const MissionSwiper = ({ list }: IProps) => {
  const screenSize = useScreenSize();

  if (screenSize.width < BREAKPOINTS.mobile) {
    return (
      <div className="gap-[16px] flex flex-col">
        {list.map(({ title, description }, index) => (
          <div
            className="mission-swiper-slide"
            key={index}
          >
            <div
              className="
              h-full w-full flex flex-col p-xl
              border-black-4 gap-[18px] border-[1px]
            "
            >
              <h6 className="text-purple-6 !font-semibold leading-[19.2px] !text-base">{title}</h6>
              <div className="description-container">
                <p className="text-gray text-sm leading-[19.6px] w-[91%] !font-normal">{description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="relative">
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        className="mission-swiper"
        grabCursor={true}
        loop={true}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        navigation={{ nextEl: '.right-mission', prevEl: '.left-mission' }}
        breakpoints={{
          1440: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 2.01,
          },
        }}
      >
        {list.map(({ title, description }, index) => (
          <SwiperSlide
            className="mission-swiper-slide"
            key={index}
          >
            <div
              className="
              h-full w-full flex flex-col p-xl
              border-black-8 gap-[18px] border-[1px]
            "
            >
              <h6 className="text-purple-6 !font-semibold leading-[19.2px] !text-base">{title}</h6>
              <div className="description-container">
                <p className="text-black-5 !text-sm w-[91%] !font-normal">{description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperNavButton
        orientation={SwiperNavArrowOrientation.LEFT}
        className="top-[40%] left-0 left-mission border-grey-6 active:bg-purple-7
          hover:disabled:border-white active:disabled:border-white"
        name="Swipe left"
      />
      <SwiperNavButton
        orientation={SwiperNavArrowOrientation.RIGHT}
        className="top-[40%] right-0 right-mission border-grey-6 active:bg-purple-7
          hover:disabled:border-white active:disabled:border-white"
        name="Swipe right"
      />
    </div>
  );
};
