import { ISwiperNavArrowProps, SwiperNavArrow } from '@/images/svg/SwiperNavArrow';
import { ButtonHTMLAttributes } from 'react';

export interface ISwiperNavButtonProps
  extends ISwiperNavArrowProps,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'> {
  className: string;
  testId?: string;
  name: string;
  arrowClassName?: string;
}

export const SwiperNavButton = ({
  orientation,
  color,
  className,
  testId,
  name,
  arrowClassName,
  ...props
}: ISwiperNavButtonProps) => (
  <button
    data-testid={testId}
    aria-label={name}
    className={`
      flex absolute justify-center items-center 
      rounded-full border w-[44px] h-[44px] 
      hover:border-purple-7 disabled:opacity-50
      active:disabled:bg-transparent
      transition ease-out duration-700
      ${className}
    `}
    {...props}
  >
    <SwiperNavArrow
      orientation={orientation}
      color={color}
      className={arrowClassName}
    />
  </button>
);
