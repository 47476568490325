'use client';

import { useState, useEffect, PropsWithChildren, useRef } from 'react';
import { motion } from 'framer-motion';
import { useIsInViewport } from '@/hooks/useIsInViewport';

interface IProps extends PropsWithChildren {
  id: string;
  className?: string;
  callback?: VoidFunction;
}

export const FadeAnimationContainer = ({ children, id, className, callback }: IProps) => {
  const ref = useRef(null);
  const [isAnimationCalled, setAnimationCall] = useState(false);

  const isInViewPort = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewPort && !isAnimationCalled) {
      setAnimationCall(true);
      if (callback) {
        callback();
      }
    }
  }, [isAnimationCalled, isInViewPort, callback]);

  return (
    <motion.div
      ref={ref}
      id={id}
      initial={{ y: 75, opacity: 0 }}
      animate={isAnimationCalled ? { y: 0, opacity: 1 } : {}}
      transition={{
        y: { duration: 0.7, ease: 'easeOut', delay: 0.3 },
        opacity: { duration: 1.2, ease: 'easeOut', delay: 0.3 },
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
};
